<template>
  <div>
    <b-card>
<!--      <div id='map-layout' class='mb-2'>-->
<!--        <MapDraw @polygonData='polygonHandleData' :polygonCoordinates='polygonData' />-->
<!--      </div>-->

      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row>
            <!-- name -->
            <b-col cols='12'>
              <LocaleTabs
                :errors='formErrors'
                name='name'
              >
                <template
                  v-for='(i,k,idx) in languages'
                  :slot='`lang${idx}`'
                  slot-scope='slotScope'
                >
                  <ValidationProvider
                    :key='idx'
                    v-slot='{ errors }'
                    :class="slotScope.selectedTab!==idx?'hidden':''"
                    :vid='`name.${k}`'
                    :name="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                    rules='required'
                  >
                    <b-form-group
                      :label="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })">
                      <b-form-input
                        v-model='form.name[k]'
                        :placeholder="$t('general.field_lang', { field: $t('labels.name'), lang: $t(`general.${i.toLocaleLowerCase()}`) })"
                      />
                      <small class='text-danger'>{{ errors[0] }}</small>
                    </b-form-group>
                  </ValidationProvider>
                </template>
              </LocaleTabs>
            </b-col>
            <b-col
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='region_id'
                :name="$t('region')"
                rules='required'
              >
                <b-form-group
                  :label="$t('region')"
                >
                  <v-select
                    v-model='form.region_id'
                    :dir='$store.state.appConfig.layout.direction'
                    label='name'
                    :options='regions'
                    :reduce='item => item.id'
                    :placeholder="$t('region')"
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                :name="$t('sort')"
                vid='sort'
                :rules='{
                         numeric: true,
                       }'
              >
                <b-form-group>
                  <template #label>
                    <span>{{ $t('sort') }}</span>
                  </template>
                  <b-form-input
                    v-model='form.sort'
                    :placeholder="$t('sort')"
                    autocomplete='off'
                    type='number'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols='3'>
              <b-card-text class='mb-0'>
                {{ $t('labels.is_searchable') }}
              </b-card-text>
              <b-form-checkbox
                v-model='form.is_searchable'
                checked='1'
                value='1'
                unchecked-value='0'
                name='check-button'
                switch
              />
            </b-col>

            <b-col cols='3'>
              <b-card-text class='mb-0'>
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model='form.status'
                checked='1'
                value='1'
                unchecked-value='0'
                name='check-button'
                switch
              />
            </b-col>


            <!--            <b-col cols='6'>-->
<!--              <validation-provider-->
<!--                v-slot='{ errors }'-->
<!--                vid='active_color'-->
<!--                :name="$t('labels.active_color')"-->
<!--              >-->
<!--                <b-card-text class='mb-1'>-->
<!--                  {{ $t('labels.active_color') }}-->
<!--                </b-card-text>-->
<!--                <b-form-input-->
<!--                  type='color'-->
<!--                  v-model='form.active_color'-->
<!--                  id='active_color'-->
<!--                  class='form-control'-->
<!--                />-->
<!--                <small class='text-danger'>{{ errors[0] }}</small>-->
<!--              </validation-provider>-->
<!--            </b-col>-->
<!--            <b-col cols='6'>-->
<!--              <validation-provider-->
<!--                v-slot='{ errors }'-->
<!--                vid='inactive_color'-->
<!--                :name="$t('labels.inactive_color')"-->
<!--              >-->
<!--                <b-card-text class='mb-1'>-->
<!--                  {{ $t('labels.inactive_color') }}-->
<!--                </b-card-text>-->
<!--                <b-form-input-->
<!--                  type='color'-->
<!--                  v-model='form.inactive_color'-->
<!--                  id='inactive_color'-->
<!--                  class='form-control'-->
<!--                />-->
<!--                <small class='text-danger'>{{ errors[0] }}</small>-->
<!--              </validation-provider>-->
<!--            </b-col>-->

            <!-- submit and reset -->
            <b-col cols='12 text-right' class='mt-3'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import MapDraw from '@/components/MapDraw.vue'
import Map from '@/components/Map.vue'

export default {
  components: { Map, MapDraw },
  mixins: [formMixin],
  data() {
    return {
      regions: [],
      polygonData: null,
      form: {
        name: {
          en: null,
          ar: null,
        },
        sort: null,
        status: 1,
        is_searchable: 0,
        region_id: null,
        active_color: null,
        inactive_color: null,
        polygon_coordinates: null,
      },
      isDataLoaded: false,

    }
  },
  created() {
    this.getRegions()
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    loadData() {
      this.axios.get(`/${this.resource}/${this.$route.params.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            status: data.status,
            is_searchable:data.is_searchable,
            region_id: data.region_id,
            sort: data.sort,
            polygon_coordinates: data.polygon_coordinates,
            active_color: data.active_color,
            inactive_color: data.inactive_color,
          }
          this.polygonData = data.polygon_coordinates
          this.isDataLoaded = true
        })
    },
    getRegions() {
      this.axios.get('/regions/list')
        .then(res => {
          const { data } = res.data
          this.regions = data
        })
    },
    async polygonHandleData(data) {
      try {
        this.polygonData = null
        if (data) {
          this.form.polygon_coordinates = data
        } else {
          this.form.polygon_coordinates = null
          console.error(`Geocoding failed`)
        }
      } catch (error) {
        console.error(error)
      }
    },
    switchMapLoader(data) {
      this.isMapLoaded = data
    },
  },
}
</script>

<style lang='scss'>
.map-app {
  display: flex;
  height: 64vh;
  padding: 0;
  flex: 1;
}

#map-layout {
  flex: 1;
  display: flex;
  position: relative;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

</style>
